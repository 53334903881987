var render = function render(){var _vm=this,_c=_vm._self._c;return _c('HeaderDialog',{attrs:{"title":`Usuários de ${_vm.item.name}`,"show":_vm.showHide,"loading":_vm.loading,"width":"750","readOnly":""},on:{"close":function($event){return _vm.$emit('close', false)}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-data-table',{attrs:{"id":"dialog_item_users_table","items":_vm.records,"headers":_vm.headers,"items-per-page":-1,"loading":_vm.loading,"fixed-header":"","sort-by":"name","hide-default-footer":""},scopedSlots:_vm._u([{key:`item.name`,fn:function({ item }){return [_c('v-row',{staticClass:"spacer ma-0",attrs:{"align":"center"}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"3","md":"3","sm":"3"}},[_c('v-avatar',{attrs:{"size":"32","left":""}},[(!item.photo)?_c('img',{attrs:{"src":require("@/assets/user-default.png")}}):_c('img',{attrs:{"src":item.photo}})])],1),_c('v-col',{staticClass:"hidden-xs-only px-0",attrs:{"cols":"10","md":"8","sm":"10"}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)]}},{key:`item.has_tokens`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-end"},[_c('v-icon',{staticClass:"mx-2",attrs:{"color":item.has_tokens ? 'accent' : 'grey',"title":item.has_tokens
                ? 'Aplicação conectada'
                : 'Aplicação foi desconectada do Google'},domProps:{"textContent":_vm._s(
              item.has_tokens
                ? 'ph-fill ph-plugs-connected'
                : 'ph-fill ph-plugs'
            )}}),_c('v-btn',{attrs:{"icon":"","title":"Sincronização individual disponível na página de usuário","color":"grey"}},[_c('v-icon',{class:`${_vm.loading ? 'icon-spinner' : ''}`,domProps:{"textContent":_vm._s('ph-arrows-clockwise')}})],1),(item.has_tokens)?_c('v-btn',{attrs:{"icon":"","title":"Desconectar aplicação","color":"accent"},on:{"click":function($event){return _vm.removeUserApps(item)}}},[_c('v-icon',{domProps:{"textContent":_vm._s('ph-link-simple-horizontal-break')}})],1):_vm._e(),_c('v-btn',{attrs:{"icon":"","title":"Disponível em breve","color":"grey"}},[_c('v-icon',{domProps:{"textContent":_vm._s('ph-trash')}})],1)],1)]}}],null,true)})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }